.technologies {
  padding: 50px 20px;
}

.skills {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.skill {
  width: 80%;
  margin: 10px 0;
}

.skill-bar {
  background-color: #1a1c2e;
  border-radius: 4px;
  height: 20px;
  margin-top: 5px;
}

.skill-level {
  background-color: #6a5acd;
  height: 100%;
  border-radius: 4px;
}

/* New styles for logos */
.skill img {
  width: 32px;
  height: 32px;
  margin-right: 10px;
}

* {
  overflow-x: hidden;
  margin: 0;
  padding: 0;
}

/* src/index.css */
@tailwind base;
@tailwind components;
@tailwind utilities;
